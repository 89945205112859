import React from "react"
import {css} from '@emotion/core'
import Layout from "../components/layout"
import Image from "../components/image";
import SEO from "../components/seo"
import { SiteWrapper, SiteContent,  FlexBox,  darkred, FlexContainerRow, mq } from "../components/styles";
import {motion} from "framer-motion";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

const SEITE = gql`
{
  seiten(where: {seite_in: Startseite}) {
    titel1
    titel2 {
      html
    }
    textblock1 {
      html
    }
  }
}

`

const IndexPage = () => {
  const {data} = useQuery(SEITE)
 return (
  <Layout layoutImage="site01"> 
    
    <SiteWrapper> 
      <SEO title="Hebammenteam" />
      <SiteContent >
      <FlexBox direction="column" align="center">
      {data && <motion.h3 css={css`width: 100%;`} >{data.seiten[0].titel1}</motion.h3>}
       
      {data && <div dangerouslySetInnerHTML={{__html: data.seiten[0].titel2.html}} css={css`width: 100%;`} /> }
      </FlexBox>
       
        <FlexContainerRow direction="row" justify="space-evenly" align="flex-start" css={{marginTop:"3em"}} >
            
            <motion.div css={mq({width: "20%", minWidth: "8em", height: "100%", order: 3, marginLeft: "10%", marginRight: "10%", display: ["none", "none", "block", "block"] })}>
              <Image image="site02" css={{width: "100%", height: "100%"}} />
            </motion.div>

            {data && <div dangerouslySetInnerHTML={{__html: data.seiten[0].textblock1.html}} css={mq({width: ["100%", "100%", "50%", "50%"], order: 1})} /> }

        </FlexContainerRow>         
  
        <FlexBox direction="column" justify="center" align="center" css={{marginTop: "6em"}}>
            <motion.h6 initial={{y:0, opacity: 0}} animate={{y:0, opacity: 1}} transition={{delay: 0.4}} css={{color: darkred}}> Hebammenleistungen werden in der Regel von allen Krankenkassen übernommen.</motion.h6> 
        </FlexBox>         
      </SiteContent>
    </SiteWrapper>
    </Layout>

)}

export default IndexPage
